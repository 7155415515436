import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { signInWithKeycloack } from '@utils/authHelpers'

export default function Login() {
  const router = useRouter()

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    const redirectRouteParameter =
      (router.query.redirect_route as string) ?? '/'

    const skipProfile = (router.query.skip_profile as string) === 'true'

    signInWithKeycloack({
      redirectRouteParameter,
      skipProfile,
    })
  }, [router])

  return null
}
